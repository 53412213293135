<template>
  <!--  <base-card>-->
  <!--    <base-button-->
  <!--      @click="setActive('stored-resources')"-->
  <!--      :mode="storedResButtonMode"-->
  <!--      >Last Watched</base-button-->
  <!--    >-->
  <!--    <base-button @click="setActive('add-resource')" :mode="addResButtonMode"-->
  <!--      >Search</base-button-->
  <!--    >-->
  <!--  </base-card>-->
  <spinner v-if="displaySpinner"></spinner>
  <tvshow-list v-if="!displaySpinner" :resources="storedResources"></tvshow-list>
</template>

<script>
import TvshowList from './TvshowList.vue';
import Spinner from "@/components/UI/Spinner.vue";
import axios from 'axios';
// import {axiosGin} from '@/api/kodiServices.js';

export default {
  components: {
    TvshowList,
    Spinner
  },
  data() {
    return {
      storedResources: [],
      activeTab: 'stored-resources',
      displaySpinner: false,
      backend_token: '96fd053a-f03e-4b5d-a070-5e3b588dc048',
    };
  },
  computed: {
    storedResButtonMode() {
      return this.activeTab === 'stored-resources' ? null : 'flat';
    },
    addResButtonMode() {
      return this.activeTab === 'add-resource' ? null : 'flat';
    },
  },
  // provide() {
  //   return {
  //     resources: this.storedResources,
  //     addResource: this.addResource,
  //     removeResource: this.removeResource,
  //   };
  // },
  methods: {
    setActive(tab) {
      this.activeTab = tab;
    },
    addResource(title, desc, url, poster) {
      this.storedResources.unshift({
        id: new Date().toISOString(),
        title: title,
        description: desc,
        link: url,
        poster: poster,
      });
      this.activeTab = 'stored-resources';
    },
    removeResource(id) {
      const resId = this.storedResources.findIndex((res) => res.id === id);
      this.storedResources.splice(resId, 1);
    },
    callTvmaze() {
      for (const idx in this.storedResources) {
        if (this.storedResources[idx].poster === null) {
          // console.log("calling tvmaze: " + this.storedResources[idx].id);
          var title = this.storedResources[idx].title.indexOf('(') > 0 ? this.storedResources[idx].title.substr(0, this.storedResources[idx].title.indexOf('(') - 1).trim() : this.storedResources[idx].title;
          title = title.replace('&', '');
          console.log("calling tvmaze: " + title);
          axios.get('https://api.tvmaze.com/search/shows?q=' + title).then(response => {
            console.log(response);
            for (const idMaze in response.data) {
              if (response.data[idMaze].show.premiered === this.storedResources[idx].premiered) {
                this.storedResources[idx].poster = response.data[idMaze].show.image.medium;
                axios.post('https://api.kodi.leknoi.dk/tvshow/poster', {
                  idShow: this.storedResources[idx].id,
                  posterUrl: response.data[idMaze].show.image.medium
                }, {
                  headers: {
                    'token': this.backend_token
                  },
                })
                    .catch(function (error) {
                      console.log(error);
                    });
              } else {
                if (response.data[idMaze].show.name === this.storedResources[idx].title) {
                  this.storedResources[idx].poster = response.data[idMaze].show.image.medium;
                  axios.post('https://api.kodi.leknoi.dk/tvshow/poster', {
                    idShow: this.storedResources[idx].id,
                    posterUrl: response.data[idMaze].show.image.medium
                  }, {
                    headers: {
                      'token': this.backend_token
                    },
                  })
                      .catch(function (error) {
                        console.log(error);
                      });
                }
              }
            }
          }).catch(error => {
            console.log(error);
          });
        }
      }
    }
  },
  mounted() {
    console.log(this.backend_token);

    this.displaySpinner = true;
    axios.get('https://api.kodi.leknoi.dk/tvshow/last?limit=50', {
      headers: {
        'token': this.backend_token
      },
    }).then(response => {
      for (const idx in response.data) {
        this.storedResources.push({
          id: response.data[idx].idShow,
          title: response.data[idx].title,
          description: response.data[idx].summary,
          premiered: response.data[idx].firstAired,
          poster: response.data[idx].posterUrl,
          studio: response.data[idx].studio,
          rating: response.data[idx].rating?.rating.toFixed(1),
        });
      }
      this.callTvmaze();
      this.displaySpinner = false;
    }).catch(error => {
      console.log(error);
    });

    axios.get('https://api.kodi.leknoi.dk/tvshow/last?limit=3000', {
      headers: {
        'token': this.backend_token
      },
    }).then(response => {
      console.log(this.storedResources.length);
      this.storedResources = [];
      for (const idx in response.data) {
        this.storedResources.push({
          id: response.data[idx].idShow,
          title: response.data[idx].title,
          description: response.data[idx].summary,
          premiered: response.data[idx].firstAired,
          poster: response.data[idx].posterUrl,
          studio: response.data[idx].studio,
          rating: response.data[idx].rating?.rating.toFixed(1),
        });
      }
      console.log(this.storedResources.length);
      this.callTvmaze();
    }).catch(error => {
      console.log(error);
    });
  },
};
</script>
