<template>
  <the-header></the-header>
  <the-tabs></the-tabs>
</template>

<script>
import TheHeader from "@/components/layouts/TheHeader.vue";

export default {
  components: {
    TheHeader
  },
  created() {
    document.title = 'Dan\'s Kodi';
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  background: #dfe0e6;
}
</style>
