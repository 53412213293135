<template>
  <search-box :send-method="search"></search-box>
  <ul>
    <learning-resource
        v-for="res in resources.filter((tvshow) => tvshow.title.toLowerCase().includes(searchText))"
        :key="res.id"
        :id="res.id"
        :title="res.title"
        :description="res.description"
        :poster="res.poster"
        :premiered="res.premiered"
        :studio="res.studio"
        :rating="res.rating"
    ></learning-resource>
  </ul>
</template>

<script>
import LearningResource from './Tvshow.vue';
import SearchBox from '../UI/SearchBox.vue'

export default {
  props: ['resources'],
  data() {
    return {
      searchText: "",
      theList: [],
    }
  },
  components: {
    LearningResource,
    SearchBox,
  },
  // inject: ['resources'],
  methods: {
    search(searchText, sortingType) {
      console.log('sortingType: ' + sortingType);
      switch (sortingType) {
        case 'watched':
          // this.resources = this.tvshowOrigList;
          break;
        // case 'rating':
        //   this.resources = this.resources.sort((a, b) => {
        //     return b.rating - a.rating;
        //   })
        //   break;
        // case 'title':
        //   this.resources = this.resources.sort((a, b) => {
        //     return b.title.toLowerCase() < a.title.toLowerCase();
        //   })
        //   break;
        case 'added':
          break;
        default:
      }

      this.searchText = searchText;
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
//margin: auto;
  /* max-width: 40rem; */
}
</style>
