<template>
  <base-dialog v-if="inputIsInvalid" title="Invalid Input" @close="confirmError">
    <template #default>
      <p>Unfortunately, at least one input value is invalid.</p>
      <p>Please correct the error entering a few characters into each field.</p>
    </template>
    <template #actions>
      <base-button @click="confirmError">Ok</base-button>
    </template>
  </base-dialog>
  <base-card>
    <form @submit.prevent="submitData" ref="addForm">
      <div class="form-control">
        <label for="title">Title</label>
        <input id="title" name="title" type="text" ref="inputTitle"/>
      </div>
      <div>
        <label for="dublet">Dublet</label>
        <input type="radio" name="search" id="dublet" value="dublet" v-model="searchType">
        <input type="radio" name="search" id="other" value="other" v-model="searchType">
      </div>
      <div>
        <base-button type="submit">Search</base-button>
      </div>
    </form>
  </base-card>
</template>

<script>
import BaseButton from '../UI/BaseButton.vue';
import BaseDialog from '../UI/BaseDialog.vue';

export default {
  components: {BaseDialog, BaseButton},
  inject: ['addResource'],
  data() {
    return {
      inputIsInvalid: false,
      searchType: "",
    };
  },
  methods: {
    submitData() {
      console.log(this.searchType);

      // const inputTitle = this.$refs.inputTitle.value.trim();
      // const inputDesc = this.$refs.inputDesc.value.trim();
      // const inputUrl = this.$refs.inputUrl.value.trim();
      //
      // if (inputTitle === '' || inputDesc === '' || inputUrl === '') {
      //   this.inputIsInvalid = true;
      //   return;
      // }
      //
      // this.addResource(inputTitle, inputDesc, inputUrl);
      this.$refs.addForm.reset();
    },
    confirmError() {
      this.inputIsInvalid = false;
    },
  },
};
</script>

<style scoped>
label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  padding: 0.15rem;
  border: 1px solid #ccc;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #3a0061;
  background-color: #f7ebff;
}

.form-control {
  margin: 1rem 0;
}
</style>
