<template>
  <base-card>
    <div class="container clearfix">
      <img :src="poster" alt="Poster"/>
      <header>
        <h3>{{ title }}</h3>
        {{ rating }}
      </header>
      <p>{{ description }}</p>
      <p>Premiered: {{ premiered }}</p>
      <p>Studio: {{ studio }}</p>
    </div>
    <div>
      <nav>
        <base-button mode="flat" @click="toggleDetails">Details</base-button>
        <base-button mode="flat" @click="toggleSeasons">Seasons</base-button>
        <base-button mode="flat" @click="removeResource(id)">Hide</base-button>
      </nav>
    </div>
    <div>
      <base-card v-if="showDetails"></base-card>
      <base-card v-if="showSeasons"></base-card>
    </div>
  </base-card>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard.vue";

export default {
  components: {BaseCard},
  data() {
    return {
      showDetails: false,
      showSeasons: false,
    };
  },
  props: ['id', 'title', 'description', 'poster', 'premiered', 'studio', 'rating'],
  inject: ['removeResource'],
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    toggleSeasons() {
      this.showSeasons = !this.showSeasons;
    }
  }
};
</script>

<style scoped>
li {
  margin: auto;
  /*  max-width: 40rem; */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer {
  display: flex;
//justify-content: space-between; //align-items: bottom;
}

h3 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
}

p {
  margin: 0.5rem 0;
}

a {
  text-decoration: none;
  color: #ce5c00;
}

a:hover,
a:active {
  color: #c89300;
}

th,
td {
  padding: 10px;
  vertical-align: top;
}

.bottom-aligned {
  display: flex;
  align-items: flex-end;
  height: 50px; /* Set a height for demonstration */
}

nav {
  display: flex;
  align-items: flex-end;
  //height: 45px; /* Set a height for demonstration */
}

img {
  float: left;
  margin-right: 10px; /* Add space between the image and the text */
  margin-bottom: 10px;
  //margin: 10px; /* Add space between the image and the text */
  width: 100px; /* Adjust as per your requirements */
  //height: 150px;
  //height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
//width: 120px;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
</style>
