<template>
  <div class="container">
    <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  data() {
    return {
      color: '#6e6c69',
      size: '8px',
    }
  },
  components: {
    PulseLoader,
  }
}
</script>

<style scoped>
.container {
  text-align: center;
  margin-top: 40px;
}
</style>
