<template>
  <div>
    <div class="container clearfix">
      <div class="container" tabindex="1">
        <div class="search-container" tabindex="1">
          <input placeholder="search" type="text" v-model.trim="searchText" @keyup="sendMethod(searchText, sortingType)"/>
          <select name="sorting" id="sorting" v-bind:value="sortingType" ref="sortingtype" @input="selectChanged()" @change="sendMethod(searchText, sortingType)">
            <option value="sorting">Sorting...</option>
            <option value="watched">Watched</option>
            <option value="title">Title</option>
            <option value="rating">Rating</option>
            <option value="added">Added</option>
          </select>
          <a class="button" @click="sendMethod(searchText, sortingType)">
            <i class="fa fa-search"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sendMethod: Function,
  },
  data() {
    return {
      searchText: "",
      sortingType: "sorting",
    }
  },
  methods: {
    selectChanged() {
      this.sortingType = this.$refs.sortingtype.value;
    }
  }
}
</script>

<style scoped>
/*
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Cabin";
}

*/
.container {
  display: block;
  max-width: 600px;
  //margin: auto;
  padding-top: 10px;
  height: 100%;
  outline: none;
}

.search-container {
  overflow: hidden;
  float: left;
  height: 4em;
  width: 4em;
  border-radius: 5em;
  box-shadow: 0 0 3px #6e6c69;
  -moz-transition: all 0.35s;
  -webkit-transition: all 0.35s;
}

.search-container:hover, .search-container:focus, .search-container:focus-within {
  width: 29em;
  border-radius: 8px 2em 2em 8px;
  outline: none;
}

.search-container:hover input, .search-container:focus input, .search-container:focus-within input {
  display: inline-block;
  margin-right: 0em;
  width: 13em;
  padding: 10px;
}

.search-container:hover select, .search-container:focus select, .search-container:focus-within select {
  display: inline-block;
  margin-right: 0em;
  width: 10em;
  padding: 5px;
}

input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  float: left;
  width: 0em;
  height: 2em;
  margin: 1em;
  margin-right: -4.5em;
  //margin-right: 1em;
  background: #fff;
  color: #6e6c69;
  font-size: 1em;
  font-weight: 600;
  padding: 0px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2) inset;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  float: left;
  width: 0em;
  height: 2.5em;
  margin: 1.3em;
  margin-right: -4.5em;
  background: #fff;
  color: #6e6c69;
  font-size: 0.8em;
  font-weight: 600;
  padding: 0px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2) inset;
  //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
}

input:focus {
  outline: none;
  box-shadow: 0 -1px 1px rgba(255, 255, 255, 0.25), 0 1px 5px rgba(0, 0, 0, 0.15);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  width: 1.75em;
  height: 1.75em;
  margin: 0.125em;
  background: #6e6c69;
  text-align: center;
  font-size: 2em;
  color: #FDF6E3;
  border-radius: 50%;
  box-shadow: 0 -1px 1px rgba(255, 255, 255, 0.25), 0 1px 1px rgba(0, 0, 0, 0.25);
  text-shadow: 0 -2px 1px rgba(0, 0, 0, 0.3);
}

.button:active {
  border: 0 !important;
  text-shadow: 0 0 0;
}

.button i {
  font-size: 85%;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

</style>